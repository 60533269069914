.inputPoint {
    margin: 0.5em;
    flex-grow: 1;
    flex-basis: 0;
}
.calcButton {
    flex-basis: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}